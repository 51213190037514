<template>
  <b-modal
    :id="idPopupCancel"
    size="lg"
    centered
    header-bg-variant="light-info"
    no-close-on-backdrop
    no-close-on-esc
    body-class="p-1"
    footer-class="justify-content-between"
    title-class="h2 font-weight-bolder text-dark"
    :title="$t('invoice.popup.cancelInvoiceTitle')"
    :ok-title="$t('save')"
    :cancel-title="$t('cancel')"
    @ok="onSave"
  >
    <div class="p-50">
      <b-container>
        <b-row
          v-if="invoiceDetail"
          class="bg-light-warning rounded p-1"
        >
          <b-col
            md="6"
            class="w-full d-grid grid-cols-2 gap-x-50 rows-min-content"
          >
            <div class="text-secondary">
              {{ $t('invoice.invoiceNo') }}:
            </div>
            <div class="text-dark font-weight-bold">
              {{ invoiceDetail.invoiceNumber }}
            </div>

            <div class="text-secondary">
              {{ $t('invoice.noSerial') }}:
            </div>
            <div class="text-dark font-weight-bold">
              {{ invoiceDetail.formAndSerial }}
            </div>

            <div class="text-secondary">
              {{ $t('invoice.company') }}:
            </div>
            <div class="text-dark font-weight-bold">
              {{ invoiceDetail.companyName }}
            </div>
          </b-col>
          <b-col
            md="6"
            class="w-full d-grid grid-cols-2 gap-x-50 rows-min-content"
          >
            <div class="text-secondary">
              {{ $t('invoice.invoiceDate') }}:
            </div>
            <div class="text-dark font-weight-bold">
              {{ invoiceDetail.createdDate }}
            </div>

            <div class="text-secondary">
              {{ $t('invoice.totalPayment') }}:
            </div>
            <div class="text-dark font-weight-bold">
              {{ invoiceDetail.totalPayment }}
            </div>
          </b-col>
        </b-row>

        <validation-observer
          ref="refFormObserver"
          tag="div"
          class="pt-1"
        >
          <b-form>
            <b-row class="mt-1">
              <!-- ANCHOR Cancellation reason -->
              <b-col>
                <b-form-group
                  class="font-weight-bold"
                >
                  <template #label>
                    {{ $t('invoice.cancelReason') }}
                    <span class="text-danger">(*)</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('invoice.cancelReason')"
                  >
                    <b-form-input
                      v-model.trim="cancelReason"
                      maxlength="255"
                      :placeholder="$t('invoice.phCancelReason')"
                      :state="errors[0] ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <div>
                  <b-form-checkbox
                    v-model="isAutoSendEmail"
                  >
                    {{ $t('invoice.autoSendEmail') }}
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <template v-if="isAutoSendEmail">
              <b-row class="mt-2">
                <!-- ANCHOR Receiver -->
                <b-col>
                  <b-form-group
                    class="font-weight-bold"
                  >
                    <template #label>
                      {{ $t('invoice.seColumns.receiver') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('invoice.seColumns.receiver')"
                    >
                      <b-form-input
                        v-model.trim="receiptNameWhenCancel"
                        maxlength="50"
                        :placeholder="$t('invoice.phReceiver')"
                        :state="errors[0] ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <!-- ANCHOR Recipient's email -->
                <b-col>
                  <b-form-group
                    class="font-weight-bold"
                  >
                    <template #label>
                      {{ $t('email') }}
                      <span class="text-danger">(*)</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      :name="$t('email')"
                    >
                      <b-form-input
                        v-model.trim="receiptMailWhenCancel"
                        maxlength="255"
                        :placeholder="$t('placeholderEmail')"
                        :state="errors[0] ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </b-form>
        </validation-observer>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'

import { useShowToast } from '../useInvoice'
import {
  hidePopupInvoiceCancel,
  useCancelReason,
  useAutoSendEmail,
} from './useInvoiceCancel'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BContainer,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
  },

  props: {
    invoiceDetail: {
      type: Object,
      default: null,
    },
    idPopupCancel: {
      type: String,
      default: 'id-popup-cancel-invoice',
    },
    // inDetailPage = true: this popup in invoice detail page
    // inDetailPage = false: this popup in invoice list page
    inDetailPage: {
      type: [Boolean, String],
      default: false,
    },
  },

  watch: {
    invoiceDetail: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.receiptNameWhenCancel = val.receiptNameWhenCancel || ''
          this.receiptMailWhenCancel = val.receiptMailWhenCancel || ''
        }
      },
    },
  },

  setup() {
    const {
      cancelReason,
    } = useCancelReason()

    const {
      isAutoSendEmail,
      receiptNameWhenCancel,
      receiptMailWhenCancel,
      onValidateForm,
      onCancelInvoice,
      onHandleFlowSignOrCancel,
      onSave,
    } = useAutoSendEmail()

    const showToast = useShowToast()

    function showToastError(message) {
      showToast(message, { success: false })
    }

    function showToastSuccess(message) {
      showToast(message, { success: true })
    }

    return {
      hidePopupInvoiceCancel,

      // from useCancelReason()
      cancelReason,

      // from useAutoSendEmail()
      isAutoSendEmail,
      receiptNameWhenCancel,
      receiptMailWhenCancel,
      onValidateForm,
      onCancelInvoice,
      onHandleFlowSignOrCancel,
      onSave,

      showToastError,
      showToastSuccess,
    }
  },
}
</script>

<style lang="scss">
.d-grid {
  display: grid;
}
.grid-cols-2 {
  grid-template-columns: auto 1fr;
  grid-template-rows: min-content;
}
.rows-min-content {
  grid-template-rows: min-content;
}
.gap-x-50 {
  column-gap: 8px;
}
</style>
