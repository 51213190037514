var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.idPopupCancel,
      "size": "lg",
      "centered": "",
      "header-bg-variant": "light-info",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "body-class": "p-1",
      "footer-class": "justify-content-between",
      "title-class": "h2 font-weight-bolder text-dark",
      "title": _vm.$t('invoice.popup.cancelInvoiceTitle'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.onSave
    }
  }, [_c('div', {
    staticClass: "p-50"
  }, [_c('b-container', [_vm.invoiceDetail ? _c('b-row', {
    staticClass: "bg-light-warning rounded p-1"
  }, [_c('b-col', {
    staticClass: "w-full d-grid grid-cols-2 gap-x-50 rows-min-content",
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceNo')) + ": ")]), _c('div', {
    staticClass: "text-dark font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.invoiceDetail.invoiceNumber) + " ")]), _c('div', {
    staticClass: "text-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.noSerial')) + ": ")]), _c('div', {
    staticClass: "text-dark font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.invoiceDetail.formAndSerial) + " ")]), _c('div', {
    staticClass: "text-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.company')) + ": ")]), _c('div', {
    staticClass: "text-dark font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.invoiceDetail.companyName) + " ")])]), _c('b-col', {
    staticClass: "w-full d-grid grid-cols-2 gap-x-50 rows-min-content",
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.invoiceDate')) + ": ")]), _c('div', {
    staticClass: "text-dark font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.invoiceDetail.createdDate) + " ")]), _c('div', {
    staticClass: "text-secondary"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.totalPayment')) + ": ")]), _c('div', {
    staticClass: "text-dark font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.invoiceDetail.totalPayment) + " ")])])], 1) : _vm._e(), _c('validation-observer', {
    ref: "refFormObserver",
    staticClass: "pt-1",
    attrs: {
      "tag": "div"
    }
  }, [_c('b-form', [_c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('b-form-group', {
    staticClass: "font-weight-bold",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('invoice.cancelReason')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('invoice.cancelReason')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "maxlength": "255",
            "placeholder": _vm.$t('invoice.phCancelReason'),
            "state": errors[0] ? false : null
          },
          model: {
            value: _vm.cancelReason,
            callback: function callback($$v) {
              _vm.cancelReason = typeof $$v === 'string' ? $$v.trim() : $$v;
            },
            expression: "cancelReason"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('div', [_c('b-form-checkbox', {
    model: {
      value: _vm.isAutoSendEmail,
      callback: function callback($$v) {
        _vm.isAutoSendEmail = $$v;
      },
      expression: "isAutoSendEmail"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.autoSendEmail')) + " ")])], 1)], 1)], 1), _vm.isAutoSendEmail ? [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', [_c('b-form-group', {
    staticClass: "font-weight-bold",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('invoice.seColumns.receiver')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])];
      },
      proxy: true
    }], null, false, 3325613957)
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('invoice.seColumns.receiver')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "maxlength": "50",
            "placeholder": _vm.$t('invoice.phReceiver'),
            "state": errors[0] ? false : null
          },
          model: {
            value: _vm.receiptNameWhenCancel,
            callback: function callback($$v) {
              _vm.receiptNameWhenCancel = typeof $$v === 'string' ? $$v.trim() : $$v;
            },
            expression: "receiptNameWhenCancel"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3038854368)
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "font-weight-bold",
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('email')) + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")])];
      },
      proxy: true
    }], null, false, 3275915154)
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('email')
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "maxlength": "255",
            "placeholder": _vm.$t('placeholderEmail'),
            "state": errors[0] ? false : null
          },
          model: {
            value: _vm.receiptMailWhenCancel,
            callback: function callback($$v) {
              _vm.receiptMailWhenCancel = typeof $$v === 'string' ? $$v.trim() : $$v;
            },
            expression: "receiptMailWhenCancel"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2184064936)
  })], 1)], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }